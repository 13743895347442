<template>
    <section class="invoice-preview-wrapper">
		<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
			<b-row>
                <b-col cols="12" md="8">
                    <b-card class="invoice-actions" no-body>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="3">
                                    <label for="date_start">Fecha Inicio</label>
                                    <flat-pickr id="date_start" v-model="filter.fechaInicio" class="form-control"
                                        :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="date_start">Fecha Fin</label>
                                    <flat-pickr id="date_start" v-model="filter.fechaFin" class="form-control"
                                        :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                                </b-col>

                                <b-col cols="12" md="3">
                                    <b-form-group label="Almacén" label-for="warehouse" label-class="font-weight-bold">
                                        <v-select id="warehouse" v-model="filter.almacenIds" :options="warehouses"
                                            label="label" :reduce="option => option.id" :clearable="true"
                                            :searchable="true" class="select-size-sm"
                                            placeholder="Todos">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="6">
                                    <b-form-group label="Tipo Proveedor" label-for="type_suppliers" label-class="font-weight-bold">
                                        <v-select id="type_suppliers" v-model="filter.tipoProveedor" :options="typeSuppliers"
                                            label="nombre" :reduce="option => option.tipo_Prov_Id" :clearable="true"
                                            :searchable="true" class="select-size-sm"
                                            placeholder="Todos">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="2" class="mt-2">
                                    <b-button @click="fetchEvents" :disabled="loading" size="sm" variant="primary" class="mr-1">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-show="events">
                <b-col cols="12" md="12">
                    <b-card no-body class="mb-0">
                        <div class="table-responsive">
                            <div class="position-relative b-table-sticky-header b-table-responsive mb-0 text-small small table-responsive">
                                <table class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 10px!important;">Proveedor</th>
                                            <th v-for="week in getWeekeOfYears" :key="week.week" style="font-size: 10px!important;" class="text-center">
                                                {{ week.label }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="processedData">
                                        <tr v-for="(proveedor, index) in processedData" :key="index">
                                            <td style="font-size: 10px!important;">{{ proveedor.proveedor }}</td>
                                            <td v-for="(total, semanaIndex) in proveedor.semanas" :key="semanaIndex" style="font-size: 10px!important;" class="text-center"
                                            @dblclick="openModal(proveedor, semanaIndex)">
                                                {{ formatCurrency(total) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot v-if="totalesGlobales && processedData">
                                        <tr>
                                            <th>Total</th>
                                            <th v-for="(total, semanaIndex) in totalesGlobales" :key="semanaIndex" class="text-center">
                                                {{ formatCurrency(total) }}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </b-card>                    
                </b-col>
            </b-row>
        </b-overlay>

        <b-modal ref="priceModalRef" id="modalPrices" no-close-on-backdrop
            title-class="font-weight-bold" hide-header hide-footer @cancel="isActiveModal = false" 
            @hidden="closeModal" :visible="isActiveModal" size="xl" style="display: flex; justify-content: center; align-items: center;">

            <template #modal-title>
                <h5 class="mb-0"><strong>Detalle</strong></h5>
            </template>

            {{ event }}
        </b-modal>
    </section>
</template>

<script>

import {
	BCard,
	BCardHeader,
	BCardText,
	BCardBody,
	BTable,
	BTableLite,
	BOverlay,
	BSpinner,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BInputGroup,
	BInputGroupPrepend,
    BFormGroup,
    BModal,

} from 'bootstrap-vue'
import moment from 'moment'
import { ref, onMounted, onUnmounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import calendarStoreModule from "./calendarStoreModule";

export default {
    name: 'CalendarWeeks',
    components: {
		BCard,
		BCardBody,
		BCardHeader,
		BCardText,
		BTable,
		BTableLite,
		BOverlay,
		BSpinner,
		BRow,
		BCol,
		BButton,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
        BFormGroup,
        BModal,

		//
		flatPickr,
		vSelect,
	},
	directives: {
		Ripple,
	},
    setup() {

        const isActiveModal = ref(false);
        const loading = ref(false);
        const filter = ref({
			fechaInicio: moment().startOf('month').format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
            almacenIds: null,
            tipoProveedor: null,
		})
        const events = ref([])
        const event = ref(null)
        const totalesGlobalesPorSemana = ref(null)

        const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

        // Register module
        if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
            store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
            store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
        });  
        
        const closeModal = () => {
            isActiveModal.value = false
        }

        const openModal = (proveedor, semanaIndex) => {
            const index = semanaIndex + 1

            const semana = proveedor.semanas[index]
            console.log(events.value.eventos.filter(event => event.proveedor === proveedor.proveedor && event.semana == `Semana ${index}`))

        //     isActiveModal.value = true
        //     event.value = proveedor
        //    // event.value.proveedor.details = proveedor.details.filter(event => moment(event.fecha).week() == semanaIndex)
        //    console.log(proveedor.semanas[semanaIndex])
        }

        const fetchTypeSuppliers = async () => {
            await store.dispatch('calendar/fetchTypeSuppliers')
        }

        const fetchWarehouses = async () => {
            await store.dispatch('calendar/fetchWarehouses')
        }

        const getWeekeOfYears = computed(() => {
            const semanas = [];
            const inicioAnio = moment().startOf('year'); // Primer día del año
            const finAnio = moment().endOf('year');     // Último día del año

            let inicioSemana = inicioAnio.clone().startOf('week'); // Primer lunes del año
            let finSemana = inicioSemana.clone().endOf('week');    // Primer domingo del año

            while (inicioSemana.isBefore(finAnio)) {
                semanas.push({
                    label: `Semana ${inicioSemana.week()}`,
                    start: inicioSemana.format('YYYY-MM-DD'),
                    end: finSemana.format('YYYY-MM-DD'),
                    week: inicioSemana.week(),
                });

                // Avanzar a la siguiente semana
                inicioSemana.add(1, 'week');
                finSemana.add(1, 'week');
            }

            return semanas.splice(0, 52);
        })

        const processedData = computed(() => {
            //if (events.value == null || events.value.resumenPorProveedorYSemana.length == 0) return [];

            if (events.value != null && events.value.resumenPorProveedorYSemana && events.value.resumenPorProveedorYSemana.length > 0) {   
                return events.value.resumenPorProveedorYSemana.map(proveedor => {
                    return {
                        proveedor: proveedor.proveedor,
                        details: _.orderBy(events.value.eventos.filter(event => event.proveedor === proveedor.proveedor), ['fecha'], ['asc']),
                        semanas: getWeekeOfYears.value.map(week => proveedor.semanas[week.label] || 0)
                    };
                });
            }
        });

        const totalesGlobales = computed(() => {
            if (totalesGlobalesPorSemana.value == null) return [];
            return getWeekeOfYears.value.map(week => totalesGlobalesPorSemana.value[week.label] || 0);
        });
        
        const warehouses = computed(() => store.getters['calendar/calendarOptions'])
        const typeSuppliers = computed(() => store.getters['calendar/tiposProveedores'])
        
        const fetchEvents = async () => {
            console.log('filter.value', filter.value)
            const query = {
                ...filter.value,
            }
            loading.value = true
            try {
                const { data } = await store.dispatch('calendar/fetchEvents', { query })
                if(data.isSuccesful) {
                    events.value = data.data
                    totalesGlobalesPorSemana.value = data.data.totalesGlobalesPorSemana
                }
            } catch (error) {
                helper.handleResponseErrors(error)
            } finally {
                loading.value = false
            }
        }        

        onMounted(async () => {
            await fetchWarehouses();
            await fetchTypeSuppliers();
            await fetchEvents();
        })

        return {
            loading,
            filter,
            events,
            isActiveModal,
            event,

            //Computed
            warehouses,
            typeSuppliers,
            getWeekeOfYears,
            processedData,
            totalesGlobales,

            //Methods
            openModal,
            closeModal,
            fetchEvents,
        }
    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 200px)!important;
}
</style>
