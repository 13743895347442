import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
    tiposProveedores: [],
  },
  getters: {
    calendarOptions: state => state.calendarOptions,
    selectedCalendars: state => state.selectedCalendars,
    tiposProveedores: state => state.tiposProveedores,
  },
  mutations: {
    SET_TIPOS_PROVEEDORES(state, val) {
      state.tiposProveedores = val
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_CALENDAR_OPTIONS(state, val) {
      state.calendarOptions = val
    }
  },
  actions: {
    fetchEvents(ctx, { query }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Compras/ObtenerEventos', { params: query })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTypeSuppliers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Proveedores/GetTipoProveedores')
          .then(response => {
            ctx.commit('SET_TIPOS_PROVEEDORES', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchWarehouses(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Almacen/ObtenerAlmacen')
          .then(response => {

            const warehouses = response.data.data;
            
            //El color de cada almacen debe ser aleatoria
            const colors = ['danger', 'primary', 'warning', 'success', 'info', 'dark'];
            const calendarOptions = warehouses.map((warehouse, index) => {
              // Asigna un color de forma cíclica utilizando el índice del almacén
              const color = colors[index % colors.length];
              return {
                color: color,
                label: warehouse.nombre,
                id: warehouse.almacen_Id,
              };
            });
            
            ctx.commit('SET_CALENDAR_OPTIONS', calendarOptions)
            ctx.commit("SET_SELECTED_EVENTS", calendarOptions);
            resolve(response)
          })
          .catch(error => reject(error))
      })
    }
  },
}
